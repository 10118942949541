@import url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.section__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.chord {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}